@tailwind base;
@tailwind components;
@tailwind utilities;

/* primary color #165740: https: //tailcolor.com/?color=165740 */
/* secondary color #B9AA7F: https: //tailcolor.com/?color=B9AA7f  */
/* accent color #eea404: https: //tailcolor.com/?color=eea404 */

@layer base {
  :root {
      --background: 0 0% 100% /* white */;
      --foreground: 0 0% 4% /* neutral-950 */;
      --card: 0 0% 100% /* white */;
      --card-foreground: 0 0% 4% /* neutral-950 */;
      --popover: 0 0% 100% /* white */;
      --popover-foreground: 0 0% 4% /* neutral-950 */;
      --primary: 158 59% 17%;
      --primary-foreground: 160 15% 92% /* -50 */;
      --secondary: 45 30% 92% /* #B9AA7F-100 */;
      --secondary-foreground: 45 19% 12% /* #B9AA7F-900 */;
      --muted: 0 0% 97% /* neutral-100 */;
      --muted-foreground: 0 0% 46% /* neutral-500 */;
      --accent: 41 89% 90% /* accent-100 */;
      --accent-foreground: 41 96% 10% /* accent-900 */;
      --destructive: 0 85% 61% /* red-500 */;
      --destructive-foreground: 0 0% 99% /* neutral-50 */;
      --border: 0 0% 90% /* neutral-200 */;
      --input: 0 0% 90% /* neutral-200 */;
      --ring: 158 59% 17% /* -600 */;
      --radius: 0.5rem;
  }

  .dark {
      --background: 0 0% 4% /* neutral-950 */;
      --foreground: 0 0% 99% /* neutral-50 */;
      --card: 0 0% 4% /* neutral-950 */;
      --card-foreground: 0 0% 99% /* neutral-50 */;
      --popover: 0 0% 4% /* neutral-950 */;
      --popover-foreground: 0 0% 99% /* neutral-50 */;
      --primary: 158 16% 53% /* -400 */;
      --primary-foreground: 160 15% 92% /* -50 */;
      --secondary: 44 18% 25% /* #B9AA7F-800 */;
      --secondary-foreground: 50 30% 96% /* #B9AA7F-50 */;
      --muted: 0 0% 15% /* neutral-800 */;
      --muted-foreground: 0 0% 64% /* neutral-400 */;
      --accent: 41 96% 19% /* accent-800 */;
      --accent-foreground: 42 85% 95% /* accent-50 */;
      --destructive: 0 63% 31% /* red-900 */;
      --destructive-foreground: 0 0% 99% /* neutral-50 */;
      --border: 0 0% 15% /* neutral-800 */;
      --input: 0 0% 15% /* neutral-800 */;
      --ring: 158 59% 17% /* -600 */;
  }
}
